.fundAccountPage {
  .fundAccountType {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 365px;
    height: 80px;

    background: #ffffff;
    /* Gray/200 */

    border: 1px solid #e1e7ec;
    box-sizing: border-box;
    border-radius: 8px;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px;
    }
    .optionText {
      display: flex;
      flex-direction: column;
      margin-left: -40px;
      margin-top: 5px;
      .title {
        font-size: 14px;
        line-height: 20px;

        /* identical to box height */
        letter-spacing: 0.01em;

        /* Gray/700 */
        color: #212934;
        margin-bottom: 7px;
      }
      .desc {
        font-size: 12px;
        line-height: 15px;

        /* Gray/500 */
        color: #8895a7;
        margin-bottom: 7px;
      }
    }
    .option {
      margin-right: 16px;
      display: flex;
    }
  }

  .addNewAccount {
    width: 365px;
    justify-content: flex-start;
    height: 64px;
    margin-top: 16px;
    .optionText {
      margin-left: 0px !important;
    }
  }

  .fundingAccount {
    border-radius: 8px 8px 0px 0px !important;
  }

  .bank_account_option {
    display: flex;
    .optionText {
      margin-left: 15px;
      p {
        margin-bottom: 5px;
      }
    }
  }

  .walletOptions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 365px;
    height: 80px;

    background: #ffffff;
    /* Gray/200 */

    border: 1px solid #e1e7ec;
    box-sizing: border-box;
    border-radius: 8px 8px 0px 0px;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px;
      .optionText {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
        margin-top: 5px;
        .title {
          font-size: 14px;
          line-height: 20px;

          /* identical to box height */
          letter-spacing: 0.01em;

          /* Gray/700 */
          color: #212934;
          margin-bottom: 7px;
        }
        .desc {
          font-size: 12px;
          line-height: 15px;

          /* Gray/500 */
          color: #8895a7;
          margin-bottom: 7px;
        }
      }
    }
    .option {
      margin-right: 16px;
      display: flex;
    }
  }

  .optionDetail {
    // width: 365px;
    // height: 70px;
    /* Gray/100 */
    background: #ffffff;
    /* Gray/200 */
    border: 1px solid #e1e7ec;
    box-sizing: border-box;
    border-radius: 0px 0px 8px 8px;
    border-top: none;
    width: 365px;

    .white_bg {
      background: #ffffff;
    }
    .lightgray_bg {
      background: #fcfdff;
    }
    .title {
      margin-left: 20px;
      margin: 11px 19px;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height */
      letter-spacing: 0.01em;
      text-transform: uppercase;

      /* Gray/700 */
      color: #212934;
    }
    .option {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      .label {
        margin: 13px 0px 8px 0px;
        font-size: 12px;
        line-height: 15px;

        /* Gray/500 */
        color: #8895a7;
      }
      .value {
        margin: 0px 0px 8px 0px;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height */

        /* Gray/700 */
        color: #212934;
      }
    }
  }

  .pdfDownloadBtn {
    margin-left: 16px;
    border: 1px solid #e1e7ec;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px rgb(27 31 35 / 5%);
    border-radius: 6px;
    color: #fff;
    border-color: #0d7fe9;
    background: #0d7fe9;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    height: 40px;
    padding: 6.4px 15px;
    font-size: 16px;
  }

  .preview {
    margin-top: 24px;
    margin-bottom: 24px;
    background: #ffffff;
    box-shadow: 0px 8.36957px 20.9239px rgba(0, 0, 0, 0.04);
    border-radius: 8px;

    .previewTitle {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      /* identical to box height, or 100% */

      display: flex;
      align-items: center;
      letter-spacing: 0.06em;
      text-transform: uppercase;

      /* Gray/700 */

      color: #212934;

      border-bottom: 0.5px solid #e1e7ec;
      padding-bottom: 24px;
      padding-left: 24px;
      padding-top: 24px;
    }

    .selectedOptions {
      padding: 16px;

      /* Gray/100 */

      background: #f5f8fa;
      border-radius: 6px;

      /* Inside auto layout */

      margin: 0px 22px 22px 22px;

      display: flex;
      flex-direction: column;
      .label {
        font-size: 14px;
        line-height: 20px;

        /* Gray/500 */

        color: #8895a7;
      }
      .value {
        font-size: 16px;
        line-height: 22px;

        /* Gray/700 */

        color: #212934;
      }
    }
  }
}

@body-background: #FCFCFE;@primary-color: #0D7FE9;@link-color: #0D7FE9;@font-family: Hero New Regular;@table-header-bg: #EFF1F7;@table-header-color: #3F3E4D;@menu-item-color: #25396F;@switch-color: #17CE89;@descriptions-extra-color: #8895A7;@form-item-margin-bottom: 10px;@tag-border-radius: 18px;