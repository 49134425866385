.logBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 20px 20px 12px;
  background: #ffffff;
  /* Gray/200 */

  border: 1px dashed #e1e7ec;
  box-sizing: border-box;
  border-radius: 8px;
  .jsonString {
    width: 475px;
  }
}

.activityLogs {
  .searchBox {
    margin: 15px 0px;
    display: flex;
    justify-content: flex-end;
  }
}

@body-background: #FCFCFE;@primary-color: #0D7FE9;@link-color: #0D7FE9;@font-family: Hero New Regular;@table-header-bg: #EFF1F7;@table-header-color: #3F3E4D;@menu-item-color: #25396F;@switch-color: #17CE89;@descriptions-extra-color: #8895A7;@form-item-margin-bottom: 10px;@tag-border-radius: 18px;