body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif; */
  font-family: 'Hero New Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
  /* word-spacing: -3px; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.page-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.ant-card-bordered {
  background: #ffffff;
  /* backoffice */

  box-shadow: 0px 2px 6px rgba(174, 174, 192, 0.1);
  border-radius: 10px;
}

.select-option-label-item {
  margin-right: 6px;
}

.input-box {
  display: flex;
  flex-direction: column;
}

.input-box label {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.rdw-editor-main {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.02);
  border: 1px solid #e8e8e8;
  min-height: 200px;
  padding: 10px;
  margin-bottom: 20px;
}

.message-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;

  background: #0d7fe9;
  border-radius: 6px;
}

.input {
  border-radius: 4px;
}

.primary-btn {
  background-color: #0d7fe9;
  padding: 10px 20px;
  color: #fff;
  border-radius: 4px;

  margin-left: auto;
}

@font-face {
  font-family: 'Hero New Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New Regular'), url('assets/fonts/Hero New Regular.woff') format('woff');
}

@font-face {
  font-family: 'Hero New Regular Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New Regular Italic'), url('assets/fonts/Hero New Regular Italic.woff') format('woff');
}

@font-face {
  font-family: 'Hero New Hairline';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New Hairline'), url('assets/fonts/Hero New Hairline.woff') format('woff');
}

@font-face {
  font-family: 'Hero New Hairline Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New Hairline Italic'), url('assets/fonts/Hero New Hairline Italic.woff') format('woff');
}

@font-face {
  font-family: 'Hero New Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New Thin'), url('assets/fonts/Hero New Thin.woff') format('woff');
}

@font-face {
  font-family: 'Hero New Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New Thin Italic'), url('assets/fonts/Hero New Thin Italic.woff') format('woff');
}

@font-face {
  font-family: 'Hero New UltraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New UltraLight'), url('assets/fonts/Hero New UltraLight.woff') format('woff');
}

@font-face {
  font-family: 'Hero New UltraLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New UltraLight Italic'), url('assets/fonts/Hero New UltraLight Italic.woff') format('woff');
}

@font-face {
  font-family: 'Hero New Light';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New Light'), url('assets/fonts/Hero New Light.woff') format('woff');
}

@font-face {
  font-family: 'Hero New Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New Light Italic'), url('assets/fonts/Hero New Light Italic.woff') format('woff');
}

@font-face {
  font-family: 'Hero New Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New Medium'), url('assets/fonts/Hero New Medium.woff') format('woff');
}

@font-face {
  font-family: 'Hero New Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New Medium Italic'), url('assets/fonts/Hero New Medium Italic.woff') format('woff');
}

@font-face {
  font-family: 'Hero New SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New SemiBold'), url('assets/fonts/Hero New SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Hero New SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New SemiBold Italic'), url('assets/fonts/Hero New SemiBold Italic.woff') format('woff');
}

@font-face {
  font-family: 'Hero New Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New Bold'), url('assets/fonts/Hero New Bold.woff') format('woff');
}

@font-face {
  font-family: 'Hero New Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New Bold Italic'), url('assets/fonts/Hero New Bold Italic.woff') format('woff');
}

@font-face {
  font-family: 'Hero New ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New ExtraBold'), url('assets/fonts/Hero New ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Hero New ExtraBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New ExtraBold Italic'), url('assets/fonts/Hero New ExtraBold Italic.woff') format('woff');
}

@font-face {
  font-family: 'Hero New Super';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New Super'), url('assets/fonts/Hero New Super.woff') format('woff');
}

@font-face {
  font-family: 'Hero New Super Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Hero New Super Italic'), url('assets/fonts/Hero New Super Italic.woff') format('woff');
}
