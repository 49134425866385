// Top Bar Styles
#TopBar {
  .bonsaHealthLogo {
    height: 40px;
  }
  .gnp_logo {
    max-height: 65px;
    object-fit: contain;
  }
  .lyftLogo {
    height: 28px;
  }
  .searchInput {
    border-radius: 8px;
    min-width: 400px;
    color: #54626c;
    margin-top: 15px;
  }
  .profilePic {
    cursor: pointer;
    p {
      margin-bottom: 0;
      padding-left: 1rem;
      line-height: 1.3rem;
    }
    img {
      border-radius: 8px;
    }
    p:nth-of-type(2) {
      font-size: 0.7rem;
    }
    .ant-dropdown-link {
      cursor: pointer;
      .downArrow {
        color: #bbcdd9;
      }
    }
  }
}

@body-background: #FCFCFE;@primary-color: #0D7FE9;@link-color: #0D7FE9;@font-family: Hero New Regular;@table-header-bg: #EFF1F7;@table-header-color: #3F3E4D;@menu-item-color: #25396F;@switch-color: #17CE89;@descriptions-extra-color: #8895A7;@form-item-margin-bottom: 10px;@tag-border-radius: 18px;