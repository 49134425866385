.blockAccountModal {
  .descriptionText {
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #5f6b7a;
  }
  label {
    font-size: 14px;
    line-height: 20px;
    color: #7c8db5;
  }
  p {
    font-size: 16px;
    line-height: 22px;
    color: #25396f;
  }
  .blueBorderd {
    border: 1px solid #0d7fe9;
  }
  .actionBtn {
    box-sizing: border-box;
    /* Button Shadow */

    box-shadow: 0px 1px 0px rgba(27, 31, 35, 0.05);
    border-radius: 6px;
  }
}

.bank_accounts {
  .searchBox {
    margin: 15px 0px;
    display: flex;
    justify-content: space-between;
  }
}

@body-background: #FCFCFE;@primary-color: #0D7FE9;@link-color: #0D7FE9;@font-family: Hero New Regular;@table-header-bg: #EFF1F7;@table-header-color: #3F3E4D;@menu-item-color: #25396F;@switch-color: #17CE89;@descriptions-extra-color: #8895A7;@form-item-margin-bottom: 10px;@tag-border-radius: 18px;