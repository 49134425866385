.settingsPage {
  .ant-descriptions-item-label {
    font-size: 16px;
    line-height: 19px;
    font-weight: bold !important;
    //   color: #7C8DB5;
  }

  .ant-descriptions-item-label {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
  }

  .borderedFormItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 16px;

    border: 1px solid #ced6de;
    box-sizing: border-box;
    border-radius: 10px;

    margin: 12px 0px;
  }

  .defaultEmailInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 14px;

    /* Gray/100 */
    background: #f5f8fa;
    border-radius: 30px;
    height: 48px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 10px 0px;

    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    /* identical to box height */
    letter-spacing: 0.01em;

    .defaultEmailType {
      /* Gray/500 */
      color: #8895a7;
      margin: 0px 8px;
    }

    .defaultEmail {
      /* Gray/600 */
      color: #5f6b7a;

      /* Inside auto layout */
      order: 0;
      margin: 0px 8px;
    }
  }

  .cardSubHeading {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    /* Gray/500 */
    color: #8895a7;
  }

  .masterWallet {
    .ant-card-body {
      padding: 0px !important;
    }
    .fundingAccount {
      border-left: 5px solid #0d7fe9;
      border-radius: 1px;
      padding-left: 25px;
      label {
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        display: flex;
        align-items: center;

        /* Gray/700 */

        color: #212934;
        margin-bottom: 12px;
        span {
          margin-right: 15px;
        }
      }
      .amount {
        font-weight: bold;
        font-size: 28px;
        line-height: 39px;
        display: flex;
        align-items: center;

        /* Gray/700 */

        color: #212934;
      }
    }
    .billingAccount {
      label {
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;

        /* Gray/500 */

        color: #8895a7;
        margin-bottom: 12px;
      }
      .amount {
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        display: flex;
        align-items: center;

        /* Gray/700 */

        color: #212934;
      }
    }
    .earnings {
      label {
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;

        /* Gray/500 */

        color: #8895a7;
        margin-bottom: 16px;
        span {
          margin-right: 15px;
        }
      }
      .amount {
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        display: flex;
        align-items: center;

        /* Aeropaye pri */

        color: #060a33;
      }
      .ant-btn {
        margin-top: 100px;
      }
    }
  }

  .teamsPage {
    .topButtons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      font-size: 18px;
    }

    .cardModal {
      .descriptionText {
        font-size: 14px;
        font-family: 'Circular Std';
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #5f6b7a;
      }
      label {
        font-size: 14px;
        line-height: 20px;
        color: #7c8db5;
      }
      p {
        font-size: 16px;
        line-height: 22px;
        color: #25396f;
      }
      .blueBorderd {
        border: 1px solid #0d7fe9;
      }
      .actionBtn {
        box-sizing: border-box;
        /* Button Shadow */

        box-shadow: 0px 1px 0px rgba(27, 31, 35, 0.05);
        border-radius: 6px;
      }
      .capitalize {
        text-transform: capitalize;
      }
    }
  }

  .custumizationTab {
    .settings-description {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      /* Gray/600 */
      color: #5f6b7a;
      width: 600px;
      height: 20px;
      left: 316px;
      top: 150px;
    }

    .alignCenter {
      display: flex;
      align-items: center;
      width: 105px;
    }

    .teal-Dot {
      height: 25px;
      width: 25px;
      border: 1px solid #09a0ca;
      background: linear-gradient(227.23deg, #0cb9e9 6.01%, #09a0ca 86.34%), #f6f6f8;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    .green-Dot {
      height: 25px;
      width: 25px;
      border: 1px solid #14874d;
      background: linear-gradient(220.36deg, #00b058 10.39%, #14874d 87.98%);
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    .black-Dot {
      height: 25px;
      width: 25px;
      border: 1px solid #212934;
      background: linear-gradient(180deg, rgba(33, 41, 52, 0.8) 0%, #212934 100%);
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    .red-Dot {
      height: 25px;
      width: 25px;
      border: 1px solid #971e31;
      background: linear-gradient(223.41deg, #d62f4b 14.22%, #a5132b 89.28%);
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    .white-Dot {
      height: 25px;
      width: 25px;
      border: 1px solid #e1e7ec;
      background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #f6f6f8;
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    .orange-Dot {
      height: 25px;
      width: 25px;
      border: 1px solid #e06104;
      background: linear-gradient(221.63deg, #ff7d05 13.79%, #d6540c 84.87%);
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    .blue-Dot {
      height: 25px;
      width: 25px;
      border: 1px solid #0059ac;
      background: linear-gradient(212.28deg, #0d7fe9 16.58%, #1155b1 84.45%);
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    .purple-Dot {
      height: 25px;
      width: 25px;
      border: 1px solid #4d36c0;
      background: linear-gradient(213.39deg, #7b61ff 13.83%, #4328cf 93.08%);
      border-radius: 50%;
      display: inline-block;
      margin-right: 10px;
    }

    .preview {
      width: 388px;
      height: 681px;
      padding: 32px;

      background: #ffffff;
      box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.04);
      border-radius: 10px;

      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .title {
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height, or 117% */

        display: flex;
        align-items: center;
        letter-spacing: 0.06em;
        text-transform: uppercase;

        color: #3f3e4d;
      }
    }
  }

  .teams {
    .searchBox {
      margin: 15px 0px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .ant-modal-body {
    padding: 0px !important;
  }
}

.securitySettingModal {
  .ant-modal-body {
    padding: 0px !important;
    /* Gray/100 */
    background: #f5f8fa;
  }
}

.QR-container {
  display: flex;
  flex-direction: column;
}

.QR-image {
  border: 1px solid #ced6de;
  border-radius: 8px;
  // padding: 1.5rem;
  margin: 0em 1em;

  width: 180px;
  height: 180px;
}

.QR-Description {
  margin-top: 0.8em;
  margin-right: 0.8em;
  padding: 0.8em;
  background: #ddefff;
  border-radius: 5px;

  text-align: left;
  line-height: 1.5em;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  /* Gray/700 */

  color: #212934;
}

.security-code {
  margin: 3.5rem 0rem 1.5rem 0rem;
}

.code-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  // .code-digit {
  //   width: 3em !important;
  //   height: 3em !important;
  //   font-size: 0.85rem;
  //   border-radius: 1px solid #0db9e9 !important;
  // }
}

.enable-button {
  margin-top: 2.5rem;
}

.enable-sms-btn {
  padding-bottom: 35px;
  width: 90%;
  padding-top: 10px;
  text-align: center;
}

.sms-btn-container {
  display: flex;
  justify-content: center;
}

.phone-input {
  background: #ffffff;
  border: 1px solid #0db9e9;
  box-shadow: 0px 0px 0px 3px rgba(67, 210, 250, 0.25);
  border-radius: 4px;
}

.phone-label {
  font-family: 'Hero New Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #212934;
}

.send-code {
  font-size: 14px;
  line-height: 16px;
  /* identical to box height */

  /* Blue/500 */

  color: #0d7fe9;
}

.phone-code-container {
  // padding-right: 15% !important;
  padding-top: 5px !important;
}

@body-background: #FCFCFE;@primary-color: #0D7FE9;@link-color: #0D7FE9;@font-family: Hero New Regular;@table-header-bg: #EFF1F7;@table-header-color: #3F3E4D;@menu-item-color: #25396F;@switch-color: #17CE89;@descriptions-extra-color: #8895A7;@form-item-margin-bottom: 10px;@tag-border-radius: 18px;