.walletOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 365px;
  height: 80px;

  background: #ffffff;
  /* Gray/200 */

  border: 1px solid #e1e7ec;
  box-sizing: border-box;
  border-radius: 8px;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px;
    .optionText {
      margin-left: 16px;
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      .title {
        font-size: 14px;
        line-height: 20px;

        /* identical to box height */
        letter-spacing: 0.01em;

        /* Gray/700 */
        color: #212934;
        margin-bottom: 7px;
      }
      .desc {
        font-size: 12px;
        line-height: 15px;

        /* Gray/500 */
        color: #8895a7;
        margin-bottom: 7px;
      }
    }
  }
  .option {
    margin-right: 16px;
    display: flex;
  }
}

@body-background: #FCFCFE;@primary-color: #0D7FE9;@link-color: #0D7FE9;@font-family: Hero New Regular;@table-header-bg: #EFF1F7;@table-header-color: #3F3E4D;@menu-item-color: #25396F;@switch-color: #17CE89;@descriptions-extra-color: #8895A7;@form-item-margin-bottom: 10px;@tag-border-radius: 18px;