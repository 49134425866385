@import './../../styles/base.less';

#MainLayout {
  padding: 0 1rem;
  background-color: @body-background;
  min-height: 100vh;
}
.header {
  background-color: @body-background !important;
}

#help-btn {
  padding-left: 24px;
  span:nth-of-type(2) {
    padding-left: 1rem;
    margin-left: 0;
  }
  &:hover,
  &:focus {
    background-color: @body-background;
    color: #1890ff;
  }
}

#contentLayout {
  // height: 100%;
  min-height: 85vh;
  section.ant-layout {
    padding: 10px 0 !important;
    background-color: @body-background;
    min-height: 100% !important;
    .ant-layout-content {
      overflow-y: hidden;
    }
    #SideBarLayout {
      background-color: @body-background;
      min-height: 100%;
      display: flex;

      div.ant-layout-sider-children {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        align-items: flex-start;
      }
    }
  }
}

.ant-table-content {
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}

@body-background: #FCFCFE;@primary-color: #0D7FE9;@link-color: #0D7FE9;@font-family: Hero New Regular;@table-header-bg: #EFF1F7;@table-header-color: #3F3E4D;@menu-item-color: #25396F;@switch-color: #17CE89;@descriptions-extra-color: #8895A7;@form-item-margin-bottom: 10px;@tag-border-radius: 18px;