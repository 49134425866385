.productMainPage {
  display: flex !important;
  justify-content: center;
  align-content: center;
  min-height: 450px !important;
  .centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 440px;
  }
}

.productType {
  display: flex;
  align-items: center;

  width: 300px;
  height: 78px;

  background: #ffffff;
  /* Gray/200 */

  border: 1px solid #e1e7ec;
  box-sizing: border-box;
  border-radius: 8px;
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    // width: 48px;
    // height: 48px;

    // /* Gray/200 */
    // border: 1px solid #e1e7ec;
    // box-sizing: border-box;
    // border-radius: 8px;

    margin: 15px;
  }
  .option {
    display: flex;
    justify-content: space-between;
    width: 65%;
  }
}

.formTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;

  /* Gray/500 */

  color: #8895a7;
}

.alignCenter {
  display: flex;
  align-items: center;
}

.whiteDot {
  height: 25px;
  width: 25px;
  border: 1px solid #e1e7ec;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #f6f6f8;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.grayDot {
  height: 25px;
  width: 25px;
  border: 1px solid #ced6de;
  background: linear-gradient(180deg, rgba(206, 214, 222, 0.65) 0%, #ced6de 100%);
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.blackDot {
  height: 25px;
  width: 25px;
  border: 1px solid #212934;
  background: linear-gradient(180deg, rgba(33, 41, 52, 0.8) 0%, #212934 100%);
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.cardHolder,
.cardHolderPreview {
  width: 304px;
  height: 472px;

  background: #ffffff;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.04);
  border-radius: 10px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .box {
    width: 240px;
    height: 375px;

    border: 1px solid #f0f0f2;
    box-sizing: border-box;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
    border-radius: 13px;
    .typeLogo {
      position: relative;
      img {
        position: absolute;
        top: 14px;
        right: 14px;
      }
    }
    .logo {
      position: relative;
      width: 240px;
      height: 375px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 150px;
      }
    }
    .typeText {
      position: relative;
      img {
        position: absolute;
        top: -40px;
        left: 14px;
      }
    }
  }

  .cardHolderTitle {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height, or 117% */

    letter-spacing: 0.06em;
    text-transform: uppercase;

    color: #3f3e4d;

    position: absolute;
    top: 20px;
    left: 65px;
  }
}

.cardHolderPreview {
  width: auto;
  height: auto;
  background: none;
  box-shadow: none;
  border-radius: none;
}

.typePreview {
  .box {
    // width: 240px;
    // height: 375px;

    border: 1px solid #f0f0f2;
    box-sizing: border-box;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
    border-radius: 13px;
    .typeLogo {
      position: relative;
      img {
        width: 30px;
        position: absolute;
        top: 14px;
        right: 14px;
      }
    }
    .logo {
      position: relative;
      width: 140px;
      height: 175px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 50px;
      }
    }
    .typeText {
      position: relative;
      img {
        width: 30px;
        position: absolute;
        top: -40px;
        left: 14px;
      }
    }
  }
}

.whiteBG {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #f6f6f8 100%);
}
.greyBG {
  background: linear-gradient(180deg, rgba(206, 214, 222, 0.65) 0%, #9fabba 100%);
}
.blackBG {
  background: linear-gradient(180deg, rgba(33, 41, 52, 0.85) 0%, #1b232d 100%);
}

.ant-radio-button-wrapper {
  margin-right: 20px !important;
}

.productPreview {
  margin-top: 24px;
  background: #ffffff;
  box-shadow: 0px 8.36957px 20.9239px rgba(0, 0, 0, 0.04);
  border-radius: 8px;

  .previewTitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height, or 100% */

    display: flex;
    align-items: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;

    /* Gray/700 */

    color: #212934;

    padding-left: 24px;
    padding-top: 24px;
  }

  .selectedOptions {
    min-height: 385px;
    padding: 16px;

    /* Gray/100 */

    background: #f5f8fa;
    border-radius: 6px;

    /* Inside auto layout */

    margin: 0px 22px 22px 22px;

    display: flex;
    flex-direction: column;
    .label {
      font-size: 14px;
      line-height: 20px;

      /* Gray/500 */

      color: #8895a7;
    }
    .value {
      font-size: 16px;
      line-height: 22px;

      /* Gray/700 */

      color: #212934;
    }
  }
}

.transactionTypes {
  background: #ffffff;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 20px 0px 0px 20px;
  .title {
    font-size: 14px;
    line-height: 14px;
    /* identical to box height, or 100% */

    letter-spacing: 0.06em;
    text-transform: uppercase;

    /* Gray/500 */

    color: #8895a7;

    margin: 20px 0px 20px 20px;
    margin-top: 20px;
  }
  .options {
    margin-left: 20px;
  }
}

.searchBar {
  padding: 15px 30px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04);
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 20px;
}

.searchBox {
  display: flex;
  justify-content: space-between;
}

.newProductBox {
  /* Gray/100 */

  background: #f5f8fa;
  /* Gray/300 */

  border: 1px dashed #ced6de;
  box-sizing: border-box;
  border-radius: 5.096px;

  /* Inside auto layout */

  // margin: 0px 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 110px;
  height: 179px;
}

.product {
  display: flex;
  .typePreview {
    // width: 94px;
    // height: 147px;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0.94) 0%, #f6f6f8 100%);
    // border: 0.5px solid #f0f0f2;
    box-sizing: border-box;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }

  .detail {
    min-width: 170px;
    // height: 147px;

    /* Gray/100 */
    background: #f5f8fa;
    border-radius: 6px;

    /* Inside auto layout */
    margin: 0px 8px;

    padding: 15px;

    .accountIcon {
      margin: 10px 0px;
    }

    label {
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      /* identical to box height */

      /* Gray/500 */

      color: #8895a7;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;

      /* Gray/700 */

      color: #212934;
    }
  }
}

@body-background: #FCFCFE;@primary-color: #0D7FE9;@link-color: #0D7FE9;@font-family: Hero New Regular;@table-header-bg: #EFF1F7;@table-header-color: #3F3E4D;@menu-item-color: #25396F;@switch-color: #17CE89;@descriptions-extra-color: #8895A7;@form-item-margin-bottom: 10px;@tag-border-radius: 18px;