// ------------ Ant Form Items CSS Wrappers - START ---------------
.loginContainer {
  .ant-input,
  .ant-select,
  // .ant-input-number-affix-wrapper,
  .ant-input-group {
    /* White */

    background: #ffffff;
    /* Gray/300 */

    // border: 1px solid #ced6de;
    // box-shadow: 0px 1px 2px rgba(63, 63, 68, 0.1);
    border-radius: 4px;
  }

  .ant-input-lg,
  .ant-select-lg {
    min-height: 48px !important;
  }

  .ant-select-selector,
  .ant-select-selection-item {
    min-height: 40px !important;
    line-height: 40px !important;
    border-radius: 4px;
  }

  .ant-form {
    .ant-select-selector,
    .ant-select-selection-item {
      min-height: 48px !important;
      line-height: 48px !important;
    }
  }

  .ant-form-vertical .ant-form-item-label {
    padding: 0px;
  }

  .ant-input-search {
    min-height: 40px !important;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    .ant-input-group {
      min-height: 40px !important;
      line-height: 40px !important;
      .ant-input-affix-wrapper {
        border: none;
        box-shadow: none;
        .ant-input {
          box-shadow: none;
        }
      }
      .ant-input-group-addon {
        border: none;
        background: none;
        box-shadow: none;
        .ant-input-search-button {
          border: none;
          box-shadow: none;
        }
      }
    }
  }

  .ant-btn-primary {
    /* Blue/500 */
    background: #0d7fe9;
    border: 1px solid rgba(33, 41, 52, 0.07);
    /* Button Shadow */
    box-shadow: 0px 1px 0px rgba(27, 31, 35, 0.05);
    border-radius: 6px;

    // word-spacing: -3px;
  }

  .ant-btn-secondary {
    /* Gray/100 */

    background: #f5f8fa;
    /* Gray/200 */

    border: 1px solid #e1e7ec;
    /* Button Shadow */

    box-shadow: 0px 1px 0px rgba(27, 31, 35, 0.05);
    border-radius: 6px;
  }

  .ant-radio-button-wrapper {
    border-radius: 4px !important;
    height: 48px !important;
    width: 135px !important;
    .alignCenter {
      margin: 5px 0px !important;
    }
  }

  .ant-descriptions-item-container {
    display: flex;
    align-items: baseline;
  }
}

.linkFundingPage {
  .ant-input-number-affix-wrapper-lg {
    padding: 0px 11px !important;
  }
}
// ------------ Ant Form Items CSS Wrappers - END ---------------
