#SideMenu {
  border-right: none !important;
  .taxiIcon {
    font-family: 'FontAwesome 5 Pro';
    left: 0px;
    top: calc(50%-40px / 2);
  }

  .selected {
    color: #ffffff;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
  }

  li.ant-menu-submenu,
  li.ant-menu-item {
    // background-color: #fcf7f2;
    margin: 15px 0 0 5px;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #25396f;

    span:nth-of-type(1) {
      padding-left: 0.3rem;
    }
    // ul.ant-menu-sub {
    //   background-color: #fcf7f2;
    //   li.ant-menu-item {
    //     background-color: #fcf7f2;
    //   }
    // }
  }
  li.ant-menu-item-selected {
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
    // color: #25396f;
    background: #0d7fe9;
    border-radius: 10px;
    color: white;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none !important;
  }
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    // font-size: 16px;
    // font-weight: bold;
    color: white;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.01em;
  }
  .logout {
    color: red;
  }
}

@body-background: #FCFCFE;@primary-color: #0D7FE9;@link-color: #0D7FE9;@font-family: Hero New Regular;@table-header-bg: #EFF1F7;@table-header-color: #3F3E4D;@menu-item-color: #25396F;@switch-color: #17CE89;@descriptions-extra-color: #8895A7;@form-item-margin-bottom: 10px;@tag-border-radius: 18px;