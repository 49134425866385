.KySyncPage {
  .description {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    /* Gray/600 */
    color: #5f6b7a;
  }

  .ant-card {
    min-height: 200px;
  }

  .row {
    margin-top: 20px;
  }

  .ant-card-meta-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    /* Gray/500 */

    color: #8895a7;
  }

  .card-footer {
    font-weight: 400;
    font-size: 16px;
    color: #0d7fe9 !important;
    margin: -10px 2px;
  }

  .card-footer2 {
    font-weight: 400;
    font-size: 16px;
    color: #8895a7 !important;
    margin: -10px 2px;
    display: flex;
  }

  .gap {
    height: 98px;
  }

  .blue {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    background: linear-gradient(217.57deg, #0db9e9 12.32%, #0c99c0 85.42%);
    /* Cyan/600 */

    border: 1px solid #0c9ccc;
    box-sizing: border-box;
    border-radius: 100px;
  }

  .kyc-ver-title {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #212934;
  }

  .kyc-ver-description {
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    color: #8895a7;
    margin-top: -10px;
  }

  .sampleFileText {
    background: #f7fbff;
    color: #5f6b7a;
    padding-top: 12px;
    padding-left: 9px;
    padding-bottom: 1px;
    border-radius: 8px;
    margin-top: -10px;
  }

  .dragDropDesc {
    background: #f7fbff;
    color: #5f6b7a;
    padding-top: 60px;
    padding-bottom: 30px;
    margin-top: 15px;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 8px;
  }

  .kyc-btn {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }

  .link {
    margin-top: 40px !important;
  }

  .plans {
    padding: 24px;

    width: 535px;
    background-color: #ffffff;
    box-shadow: 0px 2px 6px rgba(174, 174, 192, 0.1);
    border-radius: 8px;

    .plan {
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.01em;

        /* Gray/500 */

        color: #8895a7;
      }

      .hits {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height */
        letter-spacing: 0.01em;

        /* Gray/700 */
        color: #212934;

        span {
          color: gray;
        }
      }

      .note {
        /* Auto layout */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;

        width: 85px;
        height: 24px;

        /* Blue/200 */
        background: #ddefff;
        border-radius: 18px;

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          /* identical to box height, or 133% */

          display: flex;
          align-items: center;

          /* Blue/600 */
          color: #0059ac;
        }
      }
      .price {
        span {
          margin-right: 15px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 15px;
          letter-spacing: 0.01em;

          /* Gray/500 */
          // color: #8895a7;
        }
      }
    }
  }

  .fieldError {
    color: red;
    margin: 10px 0px;
    text-align: left;
  }

  .payNow {
    // padding: 24px;
    width: 535px;

    .card {
      width: 535px;
      .planDetail {
        padding: 16px;
        height: 89px;
        /* Gray/100 */

        background: #f5f8fa;
        border-radius: 6px;
        .label {
          font-size: 14px;
          line-height: 20px;
          /* identical to box height */

          /* Gray/500 */

          color: #8895a7;
        }
        .value {
          font-size: 16px;
          line-height: 22px;

          /* Gray/700 */

          color: #212934;
        }
      }
    }

    .heading {
      margin-top: 24px;

      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      /* identical to box height, or 114% */

      display: flex;
      align-items: center;

      /* Gray/700 */

      color: #212934;
    }

    .walletOptions {
      /* Auto layout */

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 16px;

      margin: 15px 0px;
      width: 535px;
      height: 64px;

      background: #ffffff;
      /* Gray/300 */

      border: 1px solid #ced6de;
      box-shadow: 0px 1px 2px rgba(63, 63, 68, 0.1);
      border-radius: 6px;
    }
  }

  .card {
    width: 488px;
    min-height: 80px !important;
    margin-top: 10px !important;
  }

  .colors-span {
    display: flex;
    align-items: center;
    width: 120px;
    // margin-top: 20px !important;
  }

  .color-info {
    background: #f7fbff;
    color: #5f6b7a;
    padding-top: 12px;
    padding-left: 9px;
    padding-bottom: 1px;
    border-radius: 8px;
    width: 663px;
  }

  .goback-btn {
    margin-top: 20px;
  }

  .settings-btn {
    margin-top: 20px;
  }

  .ant-upload {
    width: 100%;
    cursor: pointer;
  }
}

@body-background: #FCFCFE;@primary-color: #0D7FE9;@link-color: #0D7FE9;@font-family: Hero New Regular;@table-header-bg: #EFF1F7;@table-header-color: #3F3E4D;@menu-item-color: #25396F;@switch-color: #17CE89;@descriptions-extra-color: #8895A7;@form-item-margin-bottom: 10px;@tag-border-radius: 18px;