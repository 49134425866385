.content {
  margin-left: 15px;
  .title {
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Gray/700 */

    color: #212934;
    margin-bottom: 0px;
  }
  .number {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    letter-spacing: -0.4px;

    /* Gray/500 */

    color: #8895a7;
  }
}
.actions {
  display: flex;
  justify-content: flex-end;
  .tags {
  }
  .actionBtns {
    margin-left: 15px;
  }
  .actionIcons {
    margin-left: 15px;
  }
}

@body-background: #FCFCFE;@primary-color: #0D7FE9;@link-color: #0D7FE9;@font-family: Hero New Regular;@table-header-bg: #EFF1F7;@table-header-color: #3F3E4D;@menu-item-color: #25396F;@switch-color: #17CE89;@descriptions-extra-color: #8895A7;@form-item-margin-bottom: 10px;@tag-border-radius: 18px;