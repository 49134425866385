@import url('../../styles/base.less');
@import url('../../styles/antd-controls-wrapper.less');

.main-container {
  font-family: 'Hero New Regular';
  height: 100vh;
  width: 100%;
  background: #fcfcfe;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;

  .boxContainer {
    max-width: 500px;
    z-index: 2;
    min-width: 300px;
    // background-color: white;
    padding: 2rem;
    // box-shadow: 2px 3px 5px 0px rgba(38, 7, 4, 0.39);

    background: #ffffff;
    box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.04), 0px 6px 20px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
  }

  .contact-support {
    position: absolute;
    bottom: 0px;
    right: 10px;
  }

  .gnp_logo {
    width: 100%;
    margin: 20px 0px;
    max-height: 50px;
    object-fit: contain;
  }

  .resetPassword {
    display: flex;
    justify-content: center;
  }

  .securityTitle {
    font-family: 'Hero New Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 0.01em;

    /* Gray/700 */

    color: #212934;
  }

  .text-2fa {
    // font-family: 'Circular Std';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Gray/600 */

    color: #5f6b7a;
  }

  .authTitle {
    margin-top: 23px;
    margin-bottom: 12px;

    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    /* Blue/900 */

    color: #051b30;
  }

  .otpCode {
    margin: 15px 0px;
  }
}

.loginContainer {
  font-family: 'Hero New Regular';

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '';
  }

  .ant-input-affix-wrapper-lg {
    padding: 0px 11px;
  }

  .heading {
    h2 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.01em;
    }

    .description {
      // font-family: 'Circular Std';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      text-align: center;

      /* Gray/600 */
      color: #5f6b7a;
    }
  }
}

@body-background: #FCFCFE;@primary-color: #0D7FE9;@link-color: #0D7FE9;@font-family: Hero New Regular;@table-header-bg: #EFF1F7;@table-header-color: #3F3E4D;@menu-item-color: #25396F;@switch-color: #17CE89;@descriptions-extra-color: #8895A7;@form-item-margin-bottom: 10px;@tag-border-radius: 18px;