.selected-audience-box {
  /* Input field */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 12px;
  gap: 4px;

  /* colors.neutral/100 */

  background: #f5f8fa;
  /* colors.neutral/300 */

  border: 1px dashed #ced6de;
  border-radius: 4px;

  .audience-text {
    font-size: 16px;
    line-height: 16px;
    /* identical to box height, or 100% */

    /* colors.neutral/700 */

    color: #525964;
  }
  .edit-audience-link {
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Blue/500 */

    color: #0d7fe9;
  }
}

@body-background: #FCFCFE;@primary-color: #0D7FE9;@link-color: #0D7FE9;@font-family: Hero New Regular;@table-header-bg: #EFF1F7;@table-header-color: #3F3E4D;@menu-item-color: #25396F;@switch-color: #17CE89;@descriptions-extra-color: #8895A7;@form-item-margin-bottom: 10px;@tag-border-radius: 18px;