.withdrawFundForm {
  .bank_account_option {
    display: flex;
    .optionText {
      margin-left: 15px;
      p {
        margin-bottom: 5px;
      }
    }
  }

  .withdrawAll {
    .balance {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;

      /* Gray/500 */

      color: #8895a7;
    }

    .link {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;

      height: 20px;

      /* Gray/500 */
      color: #8895a7;
    }
  }

  .preview {
    margin-top: 24px;
    margin-bottom: 24px;
    background: #ffffff;
    box-shadow: 0px 8.36957px 20.9239px rgba(0, 0, 0, 0.04);
    border-radius: 8px;

    .previewTitle {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      /* identical to box height, or 100% */

      display: flex;
      align-items: center;
      letter-spacing: 0.06em;
      text-transform: uppercase;

      /* Gray/700 */

      color: #212934;

      border-bottom: 0.5px solid #e1e7ec;
      padding-bottom: 24px;
      padding-left: 24px;
      padding-top: 24px;
    }

    .selectedOptions {
      padding: 16px;

      /* Gray/100 */

      background: #f5f8fa;
      border-radius: 6px;

      /* Inside auto layout */

      margin: 0px 22px 22px 22px;

      display: flex;
      flex-direction: column;
      .label {
        font-size: 14px;
        line-height: 20px;

        /* Gray/500 */

        color: #8895a7;
      }
      .value {
        font-size: 16px;
        line-height: 22px;

        /* Gray/700 */

        color: #212934;
      }
    }
  }
}

@body-background: #FCFCFE;@primary-color: #0D7FE9;@link-color: #0D7FE9;@font-family: Hero New Regular;@table-header-bg: #EFF1F7;@table-header-color: #3F3E4D;@menu-item-color: #25396F;@switch-color: #17CE89;@descriptions-extra-color: #8895A7;@form-item-margin-bottom: 10px;@tag-border-radius: 18px;