.general-box {
  margin: 20px 0 !important;
}

.user-box-1 {
  margin-right: 10px;
}

.ant-card {
  border-radius: 8px !important;
}

.user-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 20px;

  font-size: 16px;
  letter-spacing: 1px;
  :hover {
    white-space: nowrap;
    width: auto;
    overflow: auto;
    text-overflow: '';
    overflow-wrap: anywhere;
  }
}

.label {
  font-weight: 400;
  margin-bottom: 3px;
  color: #7c8db5;
}

.value {
  font-weight: bold;
  margin-bottom: 3px;
  font-size: 14px;
  color: #25396f;
}

.image-box {
  padding: 20px;
  background: #f8f9fc;
}

.image-box .ant-row .ant-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-box .ant-row .ant-col p {
  font-size: 14px;

  margin-top: 8px;
  font-weight: bold;
  color: #7c8db5;
  margin-bottom: 0;
  // margin-left: 20px !important;
}

.ant-image-img {
  // margin-left: 20px !important;
  width: 100px;
  height: 100px;

  border-radius: 10px;
  // padding: 10px 15px;
}

.user-account-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  margin-bottom: 40px;
  border-bottom: 1px solid rgba(192, 192, 192, 0.2);

  p {
    font-size: 18px;
    font-weight: bold;
    .label {
      color: #7c8db5;
    }
    .value {
      color: black;
    }
  }
}

.box-header {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box-header .header-text {
  font-size: 20px;
  font-weight: bold;
  color: #3f3e4d;
}

.button-danger {
  width: 100%;
  border: 2px solid #e91818 !important;
  border-radius: 5px !important;
  color: #e91818 !important;
  font-weight: bold !important;
  height: 50px !important;
  font-size: 18px !important;
}

.button-success {
  width: 100%;
  border: 2px solid green !important;
  border-radius: 5px !important;
  color: green !important;
  font-weight: bold !important;
  height: 50px !important;
  font-size: 18px !important;
}

.viewEye {
  letter-spacing: 0.01em;
  /* Blue/500 */
  color: #0d7fe9;
  cursor: pointer;
}

@body-background: #FCFCFE;@primary-color: #0D7FE9;@link-color: #0D7FE9;@font-family: Hero New Regular;@table-header-bg: #EFF1F7;@table-header-color: #3F3E4D;@menu-item-color: #25396F;@switch-color: #17CE89;@descriptions-extra-color: #8895A7;@form-item-margin-bottom: 10px;@tag-border-radius: 18px;