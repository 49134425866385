.timeline-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: '#6D7786';

  margin-bottom: 24px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.audience-timeline {
  .index-title {
    // font-family: 'Circular Std';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* colors.neutral/700 */

    color: #2a2e33;

    display: flex;
    flex-direction: row;

    align-items: center;
  }

  .current-version {
    /* Blue/500 */
    background: #0d7fe9;
    border-radius: 10px;

    font-size: 10px;
    line-height: 12px;
    /* identical to box height, or 120% */

    display: flex;
    align-items: center;

    /* colors.white */

    color: #ffffff;
  }

  .bullet {
    border-radius: 4px;
    width: 16px;
    height: 16px;
    margin-right: 16px;
  }

  .next {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .bullet {
      background-color: #9bdfc4;
    }
  }

  .previous {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .bullet {
      background-color: #f99bab;
    }
  }

  .description {
    margin-top: 16px;
    margin-bottom: 16px;

    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    display: flex;
    align-items: center;

    /* colors.neutral/600 */

    color: #6d7786;
  }
}

@body-background: #FCFCFE;@primary-color: #0D7FE9;@link-color: #0D7FE9;@font-family: Hero New Regular;@table-header-bg: #EFF1F7;@table-header-color: #3F3E4D;@menu-item-color: #25396F;@switch-color: #17CE89;@descriptions-extra-color: #8895A7;@form-item-margin-bottom: 10px;@tag-border-radius: 18px;